.tablogo{
  height: 2rem;
}

.boxAlign{
  display: flex;
  align-items:center;
}

.boxAlignLabel{
  padding-left: 5px;
}
.carouselContainer {
  display: flex;
  padding: 0 55px;
  top:-85%;
  position: relative;
}

.carouselContainer:hover .carouselCard {
  transform: translateX(-25%);
}
.carouselCard:hover ~ .carouselCard {
  transform: translateX(25%);
}

.carouselCard:hover {
  transform: scale(1.5) !important;
}

.carouselCard {
  transition: transform 300ms ease 100ms;
  background: transparent;
  border: none !important;
  position: relative;
}

.carouselFrame {
  position: relative;
  padding: 20px 50px;
  height: 300px;
}

.carouselTitle {
  text-align: left;
  position: relative;
}


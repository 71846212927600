.caroCard {
/* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: #ffffff;
    height: 15rem;
    width: 10rem;
    position: relative;
}

.artwork{
    height: 15rem;
    width: 10rem;
}

.promo{
    border: #ff00f2;
    border-width: 10px;
    border-style:inset;
}

.special {
    border: red;
    border-width: 10px;
    border-style:inset;
}

.logo {
    position: absolute;
    right: 4px;
    bottom: 4px;
    height: 2rem;
    
}
/* On mouse-over, add a deeper shadow 
.card:hover {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,1);
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
}
*/
/* Add some padding inside the card container */
.container {
    padding: 0px 0px;
    color: #828282;
    font-size: 1rem;
}
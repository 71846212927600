.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  background-color: #2da3f2;
  border: none;
  position: fixed;
  bottom: 20px;
  z-index: 9999;
  right: 40px;
  padding-top: 5px;
}


.app-chatbot-button-icon {
  width: 40px;
  height: 40px;
  fill: #fff;
  vertical-align: unset;
}

.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
}

.react-chatbot-kit-chat-container {
  width: 425px;
}

.react-chatbot-kit-chat-bot-message{
  width: 425px;
}

.react-chatbot-kit-chat-container span {
  word-break:break-word;
}

.react-chatbot-kit-user-chat-message {
  word-break:break-word;
}
.navTop{
  background-image: linear-gradient(
      rgb(35, 47, 62, 1.0) 0%,
      rgb(85, 103, 125, 0.9) 100%
    );
}

.navDrop{
  background-color: rgb(35, 47, 62);
}

.customDropItem:hover{
  background-color: rgb(49, 70, 95);
}

li {
  list-style-type: none;
}
.searchBar{
  border-right-width: 0;
  border-left-width: 0;
  border-top-width: 0;
  background-color: rgba(0,0,0,0);
  color:#31465F;
  padding-left: 0;
}
.searchBar:focus{
  background-color: rgba(0,0,0,0);
  color:#E1E4E4;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25)
}

.searchBar::placeholder{
  color:#BBBBBB;
}